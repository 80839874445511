footer {
    background: $tertiary;
    // height: 340px
    padding: 5rem 0;
    overflow: hidden;
    margin-top: 80px;

    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        margin-top: 80px; }

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        margin-top: 20px; }

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        height: 100%;
        padding: 0; }

    // .footer__policy
    //     // @media only screen and (max-width: #{map-get($breakpoints, m)})
    //     //     margin-bottom: 5rem

    .footer-menu-class {
        li {
            &.active {

                a {
                    color: $primary; } } } }


    .footer__opening, .footer__policy {

        img {
            width: 12rem; } }

    .footer__wrapper {
        display: flex;
        justify-content: space-between;
        max-width: $boxed;
        row-gap: 4rem;
        margin: 0 auto;
        flex-wrap: wrap;
        padding: 6 * $base-width 2 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding: 6 * $base-width 4 * $base-width;
            row-gap: 3rem; } }

    .footer__social-media {
        gap: 10px; }

    .footer--heading {
        color: $light;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        margin: 0; }

    .footer__social-media {
        display: flex;
        a {
            margin: 0; }

        &-facebook {
            display: flex;
            gap: 1rem; } }

    .footer__social-media-icon {
        height: 3rem;
        transition: $transition;
        filter: invert(99%) sepia(3%) saturate(0%) hue-rotate(183deg) brightness(115%) contrast(100%);

        // &:hover
 }        //     filter: $filter-tertiary

    .footer__opening {
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            display: flex;
            justify-content: center;
            flex-direction: column; }
        p {
            padding: 0;
            margin: 0; }

        .footer--heading {
            padding-bottom: 2.4rem; } }

    .footer__contact-phone {
        display: flex;

        p:first-child {
            font-weight: 500;
            padding-bottom: 0; }

        p:nth-child(2) {
            margin-left: 0.5rem;
            padding-bottom: 0; } }

    .footer__contact-fax {
        display: flex;

        p:first-child {
            font-weight: 500; }

        p:nth-child(2) {
            margin-left: 0.5rem; } }

    tr {
        vertical-align: top;

        td:first-child p {
            font-weight: 700; } }

    td:nth-child(odd) {
        p {
            font-weight: 500; } }

    td:nth-child(even) {
        padding-bottom: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            padding-left: 1rem; }

        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            padding-left: 0; } }

    p {
        color: $light; }

    li {
        list-style: none; }

    a {
        color: $light;
        font-weight: 300;

        // &:hover
 }        //     color: $tertiary

    .footer__contact {
        p {
            padding-bottom: 0; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            width: 50%; }
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            width: 100%;
 } }            // margin-bottom: 50px

    .footer__opening {
        // @media only screen and (max-width: #{map-get($breakpoints, m)})
        //     width: 50%
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            width: 100%;
 } }            // margin-bottom: 50px

    .footer__policy {
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            width: 100%;
 } } }            // margin-bottom: 50px

.block.block-text-media {
    background-color: #ffffff;
    padding: 0;

    // margin-top: -62px

    // @media only screen and (max-width: #{map-get($breakpoints, s)})
    //     margin-top: -82px
    .text-media-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7.8 * $base-width 2 * $base-width;
        padding-bottom: 0;
        max-width: $boxed;
        margin: 0 auto;

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 4 * $base-width 3 * $base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex-wrap: wrap-reverse; }

        &.img-right {
            flex-direction: row; }

        &.img-left {
            flex-direction: row-reverse; }

        .text-wrapper {
            flex: 0 0 45%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%;
                margin-top: 4 * $base-width; }

            h1, .h1, h3, .h3, h2, .h2 {
                margin-bottom: 3 * $base-width; }

            .btn {
                margin-top: 3 * $base-width; } }

        .media-wrapper {
            flex: 0 0 50%;
            position: relative;
            padding-top: 40%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 100%;
                padding-top: 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding-top: 60%; }

            img, iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; } } } }

/*Variables: Form * */

$success: green;
$error: red;

.form {
  margin-top: 0;

  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    margin-top: 0; } }

.form-1 {
  @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
    margin-bottom: 5 * $base-width; }

  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    margin-bottom: $base-width; } }

.text-2 {
  margin-top: 6 * $base-width;

  @media only screen and (max-width: #{map-get($breakpoints, m)}) {
    margin-top: 0; } }

.block-customerform {
  padding: 6 * $base-width 2 * $base-width;
  max-width: $boxed;
  margin: 0 auto;

  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding: 4 * $base-width 3 * $base-width; }

  .formular-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h1 {
      flex: 0 0 100%; }

    .contact, .text {
      flex: 0 0 48%;
      margin-bottom: 8 * $base-width;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 100%;
        margin-bottom: 4 * $base-width; } } }

  .form-2 {
    .form-2-col {
      margin-top: 45px; }

    select {
      margin-bottom: 2.5rem;
      padding: 1rem; } }

  .form {
    flex: 0 0 100%; }

  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .form-1, .form-2 {
      flex: 0 0 48%;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 100%; } } }

  h3 {
    margin-bottom: 5rem; }

  button {
    width: 20rem;
    text-transform: uppercase;
    border: 0; }

  a {
    text-transform: inherit;
    letter-spacing: 0;
    font-weight: 300; }

  label {
    font-weight: 300;
    letter-spacing: 0.05rem; }

  input {
    margin-bottom: 2.5rem; }

  textarea {
    margin-bottom: 3rem;
    min-height: 13rem; }

  .form-grid {
    padding: 5rem; }

  .checkbox-text {
    font-size: 1.6rem; }

  .form-field-group {
    display: flex;
    flex-direction: column;

    input {
      height: 4rem; } }

  .form-field-group-dsvg {
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      align-items: center; }

    .checkbox {
      .dsvg-label {
        pointer-events: auto;
        position: relative;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        left: 0;
        width: 22px;
        height: 22px;
        line-height: 125%;
        border: 1px solid $tertiary;
        border-radius: 2px;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          width: 35px; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          width: 50px; }

        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
          width: 60px; }

        &:hover {
          cursor: pointer; }

        &.error {
          border-color: $error;

          &::before {
            background-color: rgba($error,.25); } } }

      input[type="checkbox"]:checked+label:before {
        content: '✖';
        background-color: $tertiary; }

      .dsvg-label::before {
        display: block;
        content: '';
        pointer-events: auto;
        position: absolute;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 125%;
        background-color: $light;
        text-align: center;
        color: $light; } }

    #dsvg {
      display: none;
      appearance: none; } }

  &__date-selection {
    margin-bottom: 4rem; } }

.form-contact-container {
  padding: 5rem;
  .form-contact-container-info {
    margin-top: 2rem; }
  .form-contact-container-item {
    display: flex;
    align-items: flex-start;
    img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
      margin-top: 0.5rem; }
    p {
      margin: 0;
      padding: 0; } } }

.form {
  #form-dsvg-container {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 2rem;

    input {
      margin-bottom: 0; } }

  #email-confirm {
    position: absolute;
    left: -20000px; } }

.form {
  .form-success,
  .form-error {
    width: 100%;
    border: 1px solid $error;
    background-color: rgba($error,.25);
    padding: 3rem;
    margin-bottom: 3rem; }

  .form-success {
    border: 1px solid $success;
    background-color: rgba($success,.25); }

  .error {
    border-color: $error;
    background-color: rgba($error,.25); } }


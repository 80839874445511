/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/buttons';

/* Blocks */
@import '../../blocks/accordion/style';
@import '../../blocks/text-media/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/form/style';
@import '../../blocks/cancelform/style';
@import '../../blocks/customerform/style';
@import '../../blocks/iframe/style';
@import '../../blocks/table/style';

/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  padding: 6 * $base-width 2 * $base-width;
  max-width: $boxed;
  margin: 0 auto;

  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding: 4 * $base-width 3 * $base-width; }

  .checkbox-text {
    font-size: 1.6rem; }

  .full-form {
    display: block !important; }

  .formular-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      flex-direction: column; }

    h1 {
      flex: 0 0 100%; }

    .contact {
      flex: 0 0 44%; }

    .contact, .text {
      // flex: 0 0 48%
      margin-top: 8 * $base-width;

      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        // flex: 0 0 100%
        margin-top: 2 * $base-width; } }

    .small {
      font-size: 1.4rem;
      margin-top: 3*$base-width; } }

  .form-field-group-dsvg {
    .checkbox {
      .dsvg-label {
        pointer-events: auto;
        position: relative;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        left: 0;
        width: 35px;
        height: 22px;
        line-height: 125%;
        border: 1px solid $tertiary;
        border-radius: 2px;

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          width: 30px; }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          width: 35px; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          width: 50px; }

        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
          width: 60px; }

        &:hover {
          cursor: pointer; }

        &.error {
          border-color: $error;

          &::before {
            background-color: rgba($error,.25); } } }

      input[type="checkbox"]:checked+label:before {
        content: '✖';
        background-color: $tertiary; }

      .dsvg-label::before {
        display: block;
        content: '';
        pointer-events: auto;
        position: absolute;
        top: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 125%;
        background-color: $light;
        text-align: center;
        color: $light; } }

    #dsvg {
      display: none;
      appearance: none; } } }

.form-2 {
  .form-2-col {
    margin-top: 45px; }

  select {
    margin-bottom: 2.5rem;
    padding: 1rem; } }

.form {
  flex: 0 0 48%;

  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    margin-bottom: 6 * $base-width; }

  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .form-1 {
      flex: 0 0 100%; }

    //   @media only screen and (max-width: #{map-get($breakpoints, xl)})
 }    //     flex: 0 0 100%

  h3 {
    margin-bottom: 5rem; }

  button {
    width: 20rem;
    text-transform: uppercase;
    border: 0;
    font-family: $font-default;
    letter-spacing: .5px;
    font-weight: 700;
    margin-top: 3 * $base-width; }

  a {
    text-transform: inherit;
    letter-spacing: 0;
    font-weight: 300; }

  label {
    font-weight: 300;
    letter-spacing: 0.05rem; }

  input, select {
    margin-bottom: 2.5rem;
    border: 1px solid lightgray; }

  textarea {
    margin-bottom: 3rem;
    min-height: 13rem;
    border: 1px solid lightgray;
    padding: 10px; }

  .form-grid {
    padding: 5rem; }

  .form-field-group {
    display: flex;
    flex-direction: column;

    input, select {
      height: 4rem;
      padding: 10px; } }

  &__date-selection {
    margin-bottom: 4rem; } }

.form-contact-container {
  padding: 5rem;
  .form-contact-container-info {
    margin-top: 2rem; }
  .form-contact-container-item {
    display: flex;
    align-items: flex-start;
    img {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
      margin-top: 0.5rem; }
    p {
      margin: 0;
      padding: 0; } } }

.form {
  #form-dsvg-container {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 2rem;

    input {
      margin-bottom: 0; } }

  #email-confirm {
    position: absolute;
    left: -20000px; } }

.form {
  .form-success,
  .form-error {
    width: 100%;
    border: 1px solid $error;
    background-color: rgba($error,.25);
    padding: 3rem;
    margin-bottom: 3rem; }

  .form-success {
    border: 1px solid $success;
    background-color: rgba($success,.25); }

  .error {
    border-color: $error;
    background-color: rgba($error,.25); } }

// Base
$base-width: .8rem;

// Fonts
$font-default: 'Nunito Sans';

// General
$dark: #222;
$light: #fff;

// Brand Colors
$primary: #ffab16;
$secondary: #ffda99;
$tertiary: #ec9700;

// Others
$divider: rgba($dark, .3);

// Widths
$max: 2560px;
$boxed: 1500px;
$small: 1250px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1285px, "xxl": 1500px, "xxxl": 1800px);

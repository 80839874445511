.block.block-iframe {
    max-width: $max;
    padding: 6 * $base-width 2 * $base-width;
    margin: 0 auto;

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 4 * $base-width 3 * $base-width; }

    .text-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .text {
            flex: 0 0 48%;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; } } }

    ul {
        padding-left: 3 * $base-width;

        li {
            margin-bottom: $base-width; } }

    .btn {
        margin: 5 * $base-width 0; } }

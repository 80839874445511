a {
    color: $primary;
    display: inline-block;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $secondary; }

    &:focus {
        outline: 0; }

    &.button {
        display: inline-block;
        text-decoration: none;
        border-radius: 10px;
        padding: 2 * $base-width 3 * $base-width;
        transition: $transition; } }

strong,
.bold {
    font-weight: 700; }


ol {
    padding-left: 5 * $base-width; }

p {
    padding-bottom: 2 * $base-width; }

button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $font-default;
    font-size: 5rem;
    line-height: 135%;
    display: block;
    font-weight: 700;
    color: $primary;
    margin-bottom: 4 * $base-width;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2.4rem;
        margin-bottom: $base-width; } }

h2,
.h2 {
    font-family: $font-default;
    font-size: 4rem;
    line-height: 140%;
    display: block;
    font-weight: 700;
    color: $primary;
    margin-bottom: 2 * $base-width;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2.4rem;
        margin-bottom: $base-width; } }

h3,
.h3 {
    font-family: $font-default;
    font-size: 2.4rem;
    line-height: 125%;
    display: block;
    font-weight: 700;
    color: $dark;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 2rem;
        margin-bottom: $base-width; } }

h4,
.h4 {
    color: $primary;
    font-size: 2rem;
    margin-bottom: 2 * $base-width; }

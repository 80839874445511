.btn {
  padding: 2 * $base-width 4 * $base-width;
  transition: $transition;

  &.btn-primary {
    background-color: $primary;
    color: $light;
    border: 2px solid $primary;

    &:hover {
      background-color: $light;
      color: $primary;
      cursor: pointer; } }

  &.btn-link {
    padding: $base-width 0;
    border-bottom: 2px solid $primary;

    &:hover {
      border-color: $secondary; } } }

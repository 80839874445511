* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.8rem;
    color: $dark;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default, 'Sans-Serif';
    overflow-x: hidden; }

img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }

main {
    padding-top: 175px;

    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding-top: 100px; } }

a:not(.btn) {
    @media (max-width: #{map-get($breakpoints, s)}) {
        overflow-wrap: break-word; } }

.global-wrapper {
    margin: auto;
    width: 100%;
    max-width: $boxed;
    position: relative; }

.block {
    @media (min-width: #{map-get($breakpoints, xs)}) {
        padding: 5 * $base-width 2.5 * $base-width; }

    @media (min-width: #{map-get($breakpoints, m)}) {
        padding: 7.5 * $base-width 6 * $base-width; }

    @media (min-width: #{map-get($breakpoints, xl)}) {
        padding: 10 * $base-width 8 * $base-width; } }

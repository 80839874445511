/*Variables: Form * */

$success: green;
$error: red;
$grey: grey;

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col-md-6 {
  @media (min-width: #{map-get($breakpoints, m)}) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px; } }

.col-md-4 {
  @media (min-width: #{map-get($breakpoints, m)}) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 15px;
    padding-left: 15px; } }

.col-md-3 {
  @media (min-width: #{map-get($breakpoints, m)}) {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 15px;
    padding-left: 15px; } }

.col-md-5 {
  @media (min-width: #{map-get($breakpoints, m)}) {
    flex: 0 0 41.66666%;
    max-width: 41.66666%;
    padding-right: 15px;
    padding-left: 15px; } }
.ml-0 {
  margin-left: 0 !important; }
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important; }
.my-8 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-8 {
  margin-top: 3rem !important; }


.cancel-form {
  font-family: "Nunito Sans", "Sans-Serif";

  .error {
    background-color: inherit !important;
    border-color: inherit !important; }

  .text-danger {
    color: #CC1618 !important; }

  .form-cancel {
    width: 100%;

    @media (min-width: #{map-get($breakpoints, s)}) {
      width: 95%;
      margin: 0 auto; }

    @media (min-width: #{map-get($breakpoints, m,)}) {
      width: 75%;
      margin: 0 auto; }

    @media (min-width: #{map-get($breakpoints, l)}) {
      width: 65%;
      margin: 0 auto; } }
  h3 {
    font-size: 2.4rem;
    color: $primary;
    margin-bottom: 1.6rem; }

  .form-cancel-wrapper {
    @media (min-width: #{map-get($breakpoints, l)}) {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto; } }

  .form-group {
    margin-bottom: 4.6rem;
    overflow: hidden;

    label {
 }      //color: $grey

    .form-control {
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      background-color: #FFF;

      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: auto;
      font-size: 1.6rem;
      color: $grey;
      padding: .5rem 0 1rem;

      @media (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 1.8rem;

        &:focus {
          box-shadow: none; } } } }

  input[type="checkbox"] {
    appearance: none;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border: 2px solid white;
    outline: 1px solid $primary;
    border-radius: 25%;
    cursor: pointer;
    &:checked {
      background-color: $primary; }
    &:checked,&:focus {
      outline: 2px solid $primary; } }

  .form-check,
  .form-check-input {
    margin-top: 0.6rem;
    margin-left: -2*$base-width;

    .form-check-label, .checkbox-field {
      padding-left: 0.5*$base-width;
      cursor: pointer; } }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .checkbox-field {
      cursor: pointer; } }

  input[type="text"],input[type="email"],input[type="date"],textarea {
    border-style: solid;
    border-width: 0 0 1px 0;
    margin-bottom: 1px;

    &:focus,&:focus-visible {
      outline: none;
      border-width: 0 0 2px 0;
      margin-bottom: 0;
      &:valid {
        border-color: $primary; }
      &:invalid {
        border-color: #CC1618; } } }

  input[type="radio"] {
    cursor: pointer;
    appearance: none;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border: 2px solid white;
    border-radius: 50%;
    outline: 1px solid $primary;
    &:checked {
      border-width: 2px;
      border: 2px solid white;
      border-radius: 50%;
      background-color: $primary;
      padding: 2px;
      &:checked,&:focus {
        outline: 2px solid $primary; } } }

  input + label {
        cursor: pointer;
        margin-bottom: 0; }

  .mail-confirm {
    position: absolute;
    left: -100vw;
    opacity: 0; }

  .checkbox-field,.checkbox {
    input {
      margin: 0; } }

  .submit-btn {
    display: flex;
    justify-content: flex-end; }

  .small {
    font-size: 1.4rem;
    margin-top: 2.4rem; } }

/*Variables: Accordion * */
$accordion-color-1: $primary;
$accordion-color-2: $primary;
$accordion-color-3: #fff;

.accordion-wrapper {}

.block-accordion {
  max-width: $small;
  margin: 0 auto;

  padding: 6 * $base-width 2 * $base-width;
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding: 4 * $base-width 3 * $base-width; }

  h2 {
    margin-bottom: 4.6rem;
    display: flex;
    justify-content: center; }

  h3 {
    width: 90%;
    font-weight: 400; }

  .accordion-text {
    margin-bottom: 50px; }

  svg {
    transform: rotate(90deg);
    height: 23px;
    transition: $transition;
    fill: $primary; }

  .accordion {
    .accordion-heading {
      padding: 19px;
      background-color: transparent;
      transition: $transition;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ededed;
      margin-bottom: 2 * $base-width;

      strong {
        font-weight: 400;
        color: $primary; }

      h3, a {
        color: #373737;
        transition: $transition;
        font-size: 2rem; }
      .accordion-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // .accordion-icon
        //   width: 25px
        //   height: 25px
        //   transition: $transition
 }        //   filter: $filter-color-1
      &:hover {
        background-color: $accordion-color-2;
        transition: $transition;
        cursor: pointer;
        strong {
          color: white; }
        h3, a {
          color: $light;
          transition: $transition; }
        svg {
          fill: $light; } } }

    .accordion-heading-active {
      background-color: $accordion-color-2;
      h3, a {
        color: $accordion-color-3;
        transition: $transition; }
      strong {
          color: white; }

      svg {
        transform: rotate(270deg);
        fill: $light;
        height: 23px;
        transition: $transition; } }

    .accordion-body {
      padding: 25px;
      overflow: hidden;

      ul {
        margin-left: 20px; } } } }

.block-table {
    max-width: $boxed;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    .headline-wrapper {
        margin-bottom: 3 * $base-width;
        text-align: center; }

    .tab::before {
        content: url('icons/swipe-left.svg');

        @media only screen and (min-width: #{map-get($breakpoints, m)}) {
            display: none; } }

    .tab {
        margin: 0 auto;
        overflow-y: none;
        overflow-x: auto;
        display: block;
        padding: 2 * $base-width;

        table {
            border-collapse: collapse;
            width: 1450px; }

        th {
            padding: 16px;
            border: 1.5px solid $primary;
            background-color: $primary;
            text-align: start;
            color: $light;
            white-space: nowrap; }

        .tarif {
            width: 200px; }

        tr {
            border-bottom: 1px solid lightgray; }

        td {
            padding: 16px; } } }

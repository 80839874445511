header {
  box-shadow: 0 0 5px rgba($dark,0.2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  background-color: $light;

  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    height: 90px; }

  nav {
    max-width: $boxed;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2 * $base-width;

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
      height: 90px; }

    .logo {
      position: relative;
      padding-top: 80px;
      width: 160px;

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 65px;
        width: 140px; }

      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        object-fit: contain;
        object-position: center; } }

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        position: absolute;
        top: 100%;
        right: -100%;
        width: 40%;
        height: calc(100vh - 112px);
        background-color: $light;
        padding: 4 * $base-width 6 * $base-width;
        transition: $transition;
        box-shadow: -2px 10px 15px rgba($dark,0.2);
        overflow-y: scroll;

        &.open {
          right: 0;
          transition: $transition; } }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        width: 100%;
        padding: 6 * $base-width 4 * $base-width; }

      ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        gap: 4 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;
          gap: 3 * $base-width; }

        li {

          a {
            color: $dark;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
              font-weight: 600;
              font-size: 2rem; } }

          &:hover {
            a {
              color: $primary; } }

          &.active {
            > a {
              color: $primary; } }

          &.children {
            position: relative;

            @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center; }

            &:after {
              content: " ";
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 407.437 407.437'%3E%3Cpolygon points='386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 '%3E%3C/polygon%3E%3C/svg%3E");
              width: 10px;
              height: auto;
              display: block;
              height: 11px;
              background-size: contain;
              background-repeat: no-repeat;
              border: 0;
              transition: $transition;
              margin: 5px;

              @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: none; } }

            button {
              display: none; }

            .level-1 {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              min-width: 200px;
              padding: $base-width;
              display: none;
              box-shadow: 0 0 5px rgba(0,0,0,0.2);
              background-color: $light;
              font-size: 1.6rem;

              @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                box-shadow: none;
                display: block;
                position: relative;
                top: unset; }

              a {
                font-size: 1.8rem;
                font-weight: 300;
                line-height: 3rem;
                padding: $base-width; } }

            &:hover {
              &:after {
                transform: rotate(180deg); }

              .level-1 {
                display: block;

                li {

                  &.active {

                    a {
                      color: $primary; } }

                  &:last-child {
                    border: none; }

                  a {
                    color: $dark;

                    &:hover {
                      color: $primary; }

                    &:active {
                      color: $primary; } } } } } } } } } }

  .navbar-toggler {
    display: none;
    border: 0;
    background: none;
    position: relative;
    transition: $transition;

    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
      display: flex;
      flex-direction: column;

      .icon-bar {
        width: 31px;
        height: 2px;
        background-color: $primary;
        display: inline-block;
        transition: $transition;

        &.middle-bar {
          margin: 6px 0; } } }

    &.open {
      transition: $transition;

      .top-bar {
        transform: rotate(40deg);
        transition: $transition; }

      .middle-bar {
        display: none;
        transition: $transition; }

      .bottom-bar {
        transform: rotate(-40deg);
        transition: $transition;
        margin-top: -1px; } } } }

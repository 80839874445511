/* nunito-sans-regular - latin */

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:  url('../../fonts/nunito-sans-v12-latin-regular.woff2') format('woff2'),
  url('../../fonts/nunito-sans-v12-latin-regular.woff') format('woff') {} }

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:  url('../../fonts/nunito-sans-v12-latin-700.woff2') format('woff2'),
  url('../../fonts/nunito-sans-v12-latin-700.woff') format('woff') {} }

/* nunito-sans-800 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src:  url('../../fonts/nunito-sans-v12-latin-800.woff2') format('woff2'),
  url('../../fonts/nunito-sans-v12-latin-800.woff') format('woff') {} }
